<template>
<div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo" >Cupons Bônus </h1>

      <!-- INIT FILTROS -->
      <div class="box-table">
        <div class="col-12 w-100 pt-0 pl-0 mt-1">
          <div class="actions d-flex  flex-nowrap ">
            <div class=" w-100 d-flex">
              <FiltroRangeDate
                class="d-flex justify-start button-dates mr-1"
                @input="filtrar"
                :value="rangeDate"
                :labelBtn="'Selecionar Data'"
                :actionLabels="{ apply: 'OK' }"
              />
            </div>
            <div class="d-flex w-100 actions flex-nowrap">

              <v-btn :class="['button-bonus mr-1', active === 6 ? 'alto' : '']" @click="active = 6"> Usuários Web </v-btn>
              <v-btn :class="['button-bonus mr-2', active === 4 ? 'alto': '']" @click="active = 4"> Agências </v-btn>

              <autoCompleteEntities class="mr-2" v-show="active === 4" :entityProps.sync="entity" :typesEntities="[4]"/>
              <autoCompleteEntities class="mr-2" v-show="active === 6" :label="'Usuários web'" :entityProps.sync="entity" :typesEntities="[6]"/>
              <!-- <v-btn large  class="mr-2 itens" tile outlined @click.prevent="getData(active, rangeDate)"> Buscar </v-btn> -->
              <v-btn :class="['button-bonus buscar']" @click.prevent="getData(active, rangeDate)"> Buscar </v-btn>
            </div>
          </div>
        </div>
        <!-- FIM FILTROS -->


        <v-skeleton-loader
          :transition="'fade-transition'"
          height="500"
          type="table">
          <!-- :search="search" -->
          <v-data-table
            :headers="headers"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 

            <template v-slot:item.dt_end="{ item }">
              {{ item.dt_end | datas('DD/MM/YYYY') }}
            </template>

            <template v-slot:item.credits="{ item }">
              {{ item.credits | currency }} 
            </template>

            <template v-slot:item.web="{ item }">
              {{ item.web | currency }} 
            </template>

            <template v-slot:item.indexados="{ item }">
              {{ item.indexados | currency }} 
            </template>

            <template v-slot:item.total="{ item }">
              {{ item.total | currency }} 
            </template>

            <template v-slot:item.action="{ item }">
                <v-btn                  
                  small
                  @click="visualizar(item)"
                  tile
                  outlined 
                  :color="variables.colorPrimary"
                  class="mr-2"
                  v-translate
                > Visualizar </v-btn>
              </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>

  <modalListaCupons />

</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import { errorSnackbar } from '@/core/service/utils'
import moment from 'moment'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'


export default {
  name: 'ListaBonusCupons',
  components: {
    // BrToolBar: () => import('./components/toolbar'),
    FiltroRangeDate: () => import('@/views/components/filtroRangeDate'),
    autoCompleteEntities: () => import('@/views/components/autoCompleteEntities'),
    modalListaCupons: () => import('./components/modalListaCupons')
  },
  mixins: [listagemTables],
  props: {
    isGefin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      typeBusca: 'entities',
      loadingPag: false,
      active: 4,
      entity: {},
      rangeDate: {
        start: '',
        end: ''
      },
    }
  },
  
  async mounted () {
    this.loadingPag = true

    this.rangeDate = {
      start: moment().format('YYYY-MM-DD'),
      end:  moment().format('YYYY-MM-DD')
    }
    this.getData(this.active, this.rangeDate)
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('bonusCupons', ['listaItens', 'totalItens']),
    headers () {
      // headers para web e revendas
      return [
        { align: 'start', class: 'table-header', text: 'Data', value: 'dt_end' },
        { align: 'start', class: 'table-header', text: 'Cod', value: 'cod' },
        { align: 'start', class: 'table-header', text: 'QTD', value: 'qtd_cupons' },
        { align: 'start', class: 'table-header', text: 'Crêditos', value: 'credits' },
        { align: 'start', class: 'table-header', text: 'Web', value: 'web' },
        { align: 'start', class: 'table-header', text: 'Indexados', value: 'indexados' },
        { align: 'start', class: 'table-header', text: 'Total', value: 'total' },
        { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
      ]
    } 
  },

  methods: {
    // ...mapActions('estabelecimento', ['buscarItem', 'clearItens']),
    ...mapActions('bonusCupons', ['getItens']),
    ...mapActions('auth', ['getUser']),

    visualizar ({ sweepstake_id }) {
      if (!this.entity.value) return errorSnackbar(`Selecione filtro ${this.active === 4 ? 'Terminal' : 'Usuário web' }`)
      
      // this.buscarItem({sweepstake_id, entityId: this.entity.value,  page: this.page, pageSize: this.$store.getters.ItensPaginacao })
      Events.$emit('open::modal::relatorio::cupons',
        {
          sweepstake_id,
          entityId: this.entity.value, 
          page: this.page, 
          pageSize: this.$store.getters.ItensPaginacao,
          text: this.entity
        })

    },

    setParams () {
      return { page: this.page, pageSize: this.$store.getters.ItensPaginacao,  _addlFilter: this.filter ? this.filter : undefined }
    },

    init () {},
    filtrar (e) {
      this.rangeDate = {
        start: e.start,
        end: e.end
      }
      console.log(e)
    },
    getData (params = 4, { start , end}) {
      this.loadingPag = true
      this.active = params
    
      this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao, type: this.active, dtStart: start, dtEnd: end, entityId: this.entity.value })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.loadingPag = false)
    }
  }
}
</script>

<style lang="scss">
@import '../../../../assets/styles/components/table';
.actions {
  width: 100%;
  .itens {
    height: 40px !important;
  }
}
.v-btn--contained.button-bonus {
    font-size: 14px;
    border-radius: 0 !important;
    color: $colorPrimary !important;
    border: 1px solid $colorPrimary !important;
    box-shadow: none !important;
    font-weight: bold !important;
    height: 35px !important;
    margin-right: 5px !important;
    font-size: 12px !important;
    border-radius: 5px !important;
    min-width: 100px !important;
    max-width: 100px !important;

    &.alto {
      background: $colorSuccess !important;
      color: white !important;
    }

    &.buscar {
      background: $colorPrimary !important;
      color: white !important;
    }
}
.button-dates {
  button {
    min-height: 34px !important
  }
}
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
